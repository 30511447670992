"use client";
import { Inter } from "next/font/google";
import "./globals.css";
import NavBar from "@/components/navbar";
import { Toaster } from "react-hot-toast";
import omit from "lodash/omit";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { setUser } from "@sentry/nextjs";
import useScreenSize from "@/hooks/withWinSize";
import useMenu from "@/hooks/withMenu";
import useStore from "@/utils/state";
import { useRouter, usePathname, useSearchParams } from "next/navigation";


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});
const inter = Inter({ subsets: ["latin"] });

const SentryUserManager = function () {
  const profile = useStore((state) => state.profile);

  useEffect(() => {
    if (profile) {
      setUser({ ...omit(profile, ["relations"]) });
    } else {
      setUser(null);
    }
  }, [profile]);
  return null;
};

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isDesktop, setIsDesktop] = useState(false);
  const { width, height } = useScreenSize();
  const menuItems = useMenu();

  /** Retro 
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const previousPathname = useRef(pathname);

  useEffect(() => {
    console.log("***NAVPrevio***:",previousPathname.current);
    console.log("***NAVPATHNAME***:",pathname);

    // Detectar el cambio de ruta
    if (previousPathname.current !== pathname || searchParams.toString() !== "") {
      console.log("***NAVPrevio dentro***:",previousPathname.current);
      console.log("***NAVPATHNAME dentro***:",pathname);
      // window.history.replaceState(null, "", pathname);

      if (pathname == '/exams/phase1'){
        console.log("***NAVActivar modal***:",previousPathname.current);
        sessionStorage.setItem('previousURL', previousPathname.current);
        // window.history.pushState(null, "", previousPathname.current);
        // throw "routeChange aborted"; // Detener la navegación
      } else {
        if(!pathname.includes("#"))
        console.log("***interpush***:",pathname);
        window.history.replaceState(null, "", `${pathname}#`);
        // window.history.pushState(null, "", pathname);
      }

      previousPathname.current = pathname;
    } else {
      if (pathname !== '/exams/phase1' && !pathname.includes("#")){
      console.log("***ELSEpush***:",pathname);
      // window.history.pushState(null, "", `${pathname}#`);
      window.history.replaceState(null, "", `${pathname}#`);
      }
    }

  }, [pathname, searchParams]);
   retro end */

  useEffect(() => {
    setIsDesktop(width >= 860);
  }, [setIsDesktop, width, height]);

  return (
    <html
      lang="en"
      style={{
        background: "white!important",
      }}
    >
      <head>
        <title>CGI Exam and Study Application</title>
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        <script
          dangerouslySetInnerHTML={{ __html: "window.globalThis = window" }}
          type="text/javascript"
        />
      </head>
      <body
        className={inter.className}
        style={{
          background: "white!important",
          color: "black!important",
        }}
      >
        <QueryClientProvider client={queryClient}>
          {(!isDesktop && (
            <>
              <NavBar />
              <div className="container mx-auto">{children}</div>
              <footer
                data-theme="light"
                className="mt-10 footer items-center p-4 text-neutral-content"
              ></footer>
            </>
          )) || (
            <>
              <NavBar navMenu={false} />
              <div className="grid grid-cols-[208px_auto]">
                <ul className="menu p-4 w-52 min-h-full bg-base-200 text-base-content">
                  {menuItems.map((item) => {
                    return item === "separator" ? (
                      <div
                        className="divider"
                        key={Date.now() * Math.random()}
                      />
                    ) : (
                      <li key={item.key}>{item}</li>
                    );
                  })}
                </ul>
                {children}
              </div>
            </>
          )}
          <Toaster />
        </QueryClientProvider>
        <SentryUserManager />
      </body>
    </html>
  );
}
