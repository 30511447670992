import { Fragment, useEffect, useRef } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { LucideIcon } from 'lucide-react'
import clsx from 'clsx'

export interface DropdownProps {
  icon: LucideIcon;
  items: any[];
  orientation: string;
}

const Dropdown = function(props) {
  const refBtn = useRef()

  /*useEffect(() => {
    if (window.screen.width >= 1280 && props.open && refBtn && refBtn.current) {
      (refBtn.current as any).click()
    }
  }, [refBtn])*/

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="btn btn-ghost btn-circle" ref={refBtn}>
          {props.icon}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(
            props.orientation === 'right' && 'right-0',
            "absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-base-100 rounded-box shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          )}
        >
          <div className="py-1">
            <ul tabIndex={0} className="menu">
              {props.items.map(item => {
                return item === 'separator' ? <div className="divider" key={Date.now() * Math.random()} /> : (
                  <li key={item.key}>
                    <Menu.Item>{item}</Menu.Item>
                  </li>
                )
              })}
            </ul>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default Dropdown
