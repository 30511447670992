import Config from '@/config/default'
import * as AWS from 'aws-sdk'

const { AWS: AWSConfig } = Config

export class Upload {
  public uploadPhotoId(file: any, fileName: string): Promise<any> {
    this.AWSLoginConfig()

    const s3 = this.AWSS3PhotoIdConfig()
    let uploadPromise = s3.upload({
      Key: fileName,
      ContentType: file.type,
      Bucket: AWSConfig.PhotoIDUploadBucket,
      Body: file,
      ACL: 'public-read'
    }).promise()

    return uploadPromise.then(
      (data) => {
        return data
      },
      (error) => {
        this.handleError(error)
      }
    )
  }

  public uploadVideoResponse(file: any, fileName: string): Promise<any> {
    this.AWSLoginConfig()

    const s3 = this.AWSSVideoResponseConfig()
    const uploadPromise = s3.upload({
      Key: fileName,
      Bucket: AWSConfig.VideoResponseUploadBucket,
      Body: file,
      ACL: 'public-read'
    }, { queueSize: 4, partSize: 1024 * 1024 * 5 }).promise()

    return uploadPromise
  }

  public uploadVideoResponseTest(file: any, fileName: string): Promise<any> {
    this.AWSLoginConfig()

    const s3 = this.AWSSVideoResponseConfig()
    const uploadPromise = s3.upload({
      Key: fileName,
      Bucket: AWSConfig.VideoResponseTestUploadBucket,
      Body: file,
      ACL: 'public-read'
    }, { queueSize: 4, partSize: 1024 * 1024 * 5 }).promise()

    return uploadPromise
  }

  private AWSLoginConfig(): void {
    AWS.config.region = AWSConfig.S3Region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: AWSConfig.UploadIdentityPoolId,
    })
  }

  private AWSS3PhotoIdConfig(): AWS.S3 {
    const s3 = new AWS.S3({
      apiVersion: 'latest',
      params: { Bucket: AWSConfig.PhotoIDUploadBucket }
    })

    return s3
  }

  private AWSSVideoResponseConfig(): AWS.S3 {
    const s3 = new AWS.S3({
      apiVersion: 'latest',
      params: { Bucket: AWSConfig.PhotoIDUploadBucket }
    })

    return s3
  }

  private handleError(error: any): void {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message)
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`)
    }
  }
}

