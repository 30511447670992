"use client";

import Dropdown from "@/components/dropdown";
import useMenu from "@/hooks/withMenu";
import useStore from "@/utils/state";
import { Menu, UserRound } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import NetworkSpeedMonitor from "./network";

export default function NavBar({ navMenu = true }) {
  const router = useRouter();
  const menuItems = useMenu();
  const [isAuth, setIsAuth] = useState(false);
  const [logout, setLogout] = useState(false);
  const token = useStore((state) => state.token);
  const reset = useStore((state) => state.reset);
  const profile = useStore((state) => state.profile);

  useEffect(() => {
    if (token) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, [setIsAuth, token]);

  useEffect(() => {
    if (logout && reset) {
      reset();
      setLogout(false);
      router.replace("/");
    }
  }, [logout, setLogout, reset, router]);

  return (
    <div className="navbar bg-base-100" data-theme="autumn">
      <div className="navbar-start">
        {(navMenu && (
          <Dropdown
            icon={<Menu size={40} strokeWidth={1} />}
            items={menuItems}
            open={true}
          />
        )) ||
          null}
      </div>
      <div className="navbar-center">
        <Link href="/" className="btn btn-ghost text-xl">
          <Image src="/world-ico.png" width={40} height={40} alt="" />
          Certified
          <span style={{ color: "red", display: "contents" }}>Spanish</span>
        </Link>
      </div>
      <div className="navbar-end">
        <div className="grid grid-cols-2 gap-2">
          {(profile?.id && <NetworkSpeedMonitor id={profile.id} />) || <></>}
          {!isAuth ? (
            <></>
          ) : (
            <Dropdown
              orientation="right"
              icon={<UserRound size={40} strokeWidth={1} />}
              items={[
                <Link key="user-profile" href="/profile">
                  Profile
                </Link>,
                <Link
                  key="user-logout"
                  href="#"
                  onClick={() => setLogout(true)}
                >
                  Logout
                </Link>,
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
}
